import React, { useCallback, useContext, useMemo } from 'react';
import { FunctionOutlined } from '@ant-design/icons';
import { Block } from '../../../../../Common/Sider';
import AddableList from './AddableList/AddableList';
import { config as Indicators, list as indicatorsList } from '../../../constants/indicators';
import { config as WidgetTypes } from '../../../constants/widgetTypes';
import WidgetContext from './widget.context';

const IndicatorAddableList = () => {
  const { widget, updateWidget } = useContext(WidgetContext);

  const widgetType = WidgetTypes[widget.type];

  const itemList = useMemo(
    () =>
      widget &&
      indicatorsList
        .filter(
          (d) => !widget.indicators.includes(d) && !widgetType.forbiddenIndicatorTypes?.includes(Indicators[d].unit),
        )
        .map((d) => ({ ...Indicators[d], icon: FunctionOutlined })),
    [widget],
  );

  const handleAdd = useCallback(
    (indicatorId) => {
      if (Indicators[indicatorId]) {
        updateWidget({
          ...widget,
          indicators: [...widget.indicators, indicatorId],
        });
      }
    },
    [widget, updateWidget],
  );

  return (
    <Block key="add-indicators" title="Add indicators" description="Select indicators in the list bellow">
      <AddableList
        itemList={itemList}
        onAdd={handleAdd}
        placeholder="Search indicator"
        subtitle="Most used indicators"
      />
    </Block>
  );
};

export default IndicatorAddableList;
