import React, { useCallback, useContext, useMemo } from 'react';
import { CodeSandboxOutlined } from '@ant-design/icons';
import { useProgramManagerId } from '../../../../../../../contexts/app.context';
import { Block } from '../../../../../Common/Sider';
import AddableList from './AddableList/AddableList';
import { config as Dimensions, list as dimensionsList } from '../../../constants/dimensions';
import { config as WidgetTypes } from '../../../constants/widgetTypes';
import WidgetContext from './widget.context';

const DimensionAddableList = () => {
  const [programManagerId] = useProgramManagerId();

  const { widget, updateWidget } = useContext(WidgetContext);

  const widgetType = WidgetTypes[widget.type];

  const itemList = useMemo(
    () =>
      widget &&
      dimensionsList
        .filter(
          (d) =>
            !widget.dimensions.includes(d) &&
            !widgetType.forbiddenDimensionTypes?.includes(Dimensions[d].axis) &&
            !(Dimensions[d].internalOnly && !!programManagerId),
        )
        .map((d) => ({ ...Dimensions[d], icon: CodeSandboxOutlined })),
    [widget],
  );

  const handleAdd = useCallback(
    (dimensionId) => {
      if (Dimensions[dimensionId]) {
        updateWidget({
          ...widget,
          dimensions: [...widget.dimensions, dimensionId],
        });
      }
    },
    [widget, updateWidget],
  );

  return (
    <Block key="add-dimensions" title="Add dimensions" description="Select dimensions in the list bellow">
      <AddableList
        itemList={itemList}
        onAdd={handleAdd}
        placeholder="Search dimension"
        subtitle="Most used dimensions"
      />
    </Block>
  );
};

export default DimensionAddableList;
