import React, { useMemo } from 'react';
import { Row, Table as AntTable } from 'antd';
import { useQuery as useApolloQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { compareByProp } from '../../../../../../util/array';
import useSearchFilters from '../../../hooks/useSearchFilters';
import useBaseCurrency from '../../../hooks/useBaseCurrency';
import { config as Dimensions } from '../../constants/dimensions';
import { config as Indicators } from '../../constants/indicators';
import Axis from '../../constants/axis';
import Units from '../../constants/units';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import { brainpowerQuery } from '../Common/query';
import DataExportMenu from '../Common/DataExportMenu';
import styles from './chart.module.scss';
import useBaseGranularity, { granularities } from '../../../hooks/useBaseGranularity';
import { amountFormatter } from '../../../../../../util/formatter';

const EMPTY_STRING = 'No label';

const renderByAxis = (axis, { granularity }) => {
  switch (axis) {
    case Axis.Date:
      return (value) =>
        value === EMPTY_STRING ? EMPTY_STRING : moment(value).format(granularities[granularity].format);
    default:
      return (value) => value;
  }
};

const renderByUnit = (unit, { currency, format }) => {
  switch (unit) {
    case Units.Amount:
      return (value) => (value === EMPTY_STRING ? EMPTY_STRING : amountFormatter(value, currency, format));
    case Units.Percent:
      return (value) => (value === EMPTY_STRING ? EMPTY_STRING : `${value?.toFixed(2)} %`);
    case Units.Checkout:
    case Units.Order:
    case Units.Transaction:
    case Units.Customer:
    default:
      return (value) => value;
  }
};

const Table = (props) => {
  const { widget, layout } = props;

  const [baseCurrency] = useBaseCurrency();
  const [baseGranularity] = useBaseGranularity();
  const { list } = useSearchFilters();

  const widgetType = useMemo(() => {
    if (!WidgetTypes[widget.type]) {
      throw new Error(`Widget type ${widget.type} does not exist.`, widget.i);
    }
    return WidgetTypes[widget.type];
  }, [widget]);

  const dimensionsConfig = widget.dimensions.slice(0, widgetType.maxDimensions).map((d) => {
    if (Dimensions[d]) {
      return Dimensions[d];
    }
    throw new Error(`Dimension ${d} does not exist.`, widget.i);
  });

  const indicatorsConfig = widget.indicators.slice(0, widgetType.maxIndicators).map((i) => {
    if (Indicators[i]) {
      return Indicators[i];
    }
    throw new Error(`Indicator ${i} does not exist.`, widget.i);
  });

  const timezone = 'UTC';

  const params = useMemo(
    () => ({
      filters: list.map((filter) => ({
        name: filter.key,
        value: filter.value,
        excluded: filter.excluded,
      })),
      indicators: widget.indicators.slice(0, widgetType.maxIndicators),
      dimensions: widget.dimensions.slice(0, widgetType.maxDimensions),
      timezone,
      currency: baseCurrency,
      granularity: baseGranularity,
      ...widgetType.params?.(widget),
    }),
    [baseCurrency, baseGranularity, list, widget, widgetType],
  );

  const { data, loading } = useApolloQuery(brainpowerQuery, {
    variables: {
      service: widgetType.endpoint,
      parameters: params,
    },
  });

  const columns = useMemo(
    () => [
      ...dimensionsConfig.map((dimensionConfig) => ({
        title: dimensionConfig.label,
        dataIndex: dimensionConfig.id,
        key: dimensionConfig.id,
        ellipsis: true,
        fixed: true,
        defaultSortOrder: 'ascend',
        render: renderByAxis(dimensionConfig.axis, { granularity: baseGranularity }),
        sorter: compareByProp(dimensionConfig.id, dimensionConfig.axis === Axis.Date ? 'desc' : 'asc', 'string'),
      })),
      ...indicatorsConfig.map((indicatorConfig) => ({
        title: indicatorConfig.label,
        dataIndex: indicatorConfig.id,
        key: indicatorConfig.id,
        ellipsis: true,
        align: 'right',
        render: renderByUnit(indicatorConfig.unit, { currency: baseCurrency, format: widget.options?.amount_format }),
        sorter: compareByProp(indicatorConfig.id),
      })),
    ],
    [dimensionsConfig, indicatorsConfig, widget],
  );

  const dataSource = useMemo(() => {
    if (!data?.brainpower?.data) return [];
    if (dimensionsConfig.length === 2) {
      // flatten data if 2 dimensions
      return data.brainpower.data.reduce((memo, parent, index) => {
        const { [dimensionsConfig[0].id]: dim1Value, ...dim2Data } = parent;
        return [
          ...memo,
          ...Object.keys(dim2Data).reduce(
            (memo2, dim2Value, index2) => [
              ...memo2,
              {
                key: `row_${index + 1}_${index2 + 1}`,
                [dimensionsConfig[0].id]: dim1Value,
                [dimensionsConfig[1].id]: dim2Value,
                ...(indicatorsConfig.length > 1
                  ? dim2Data[dim2Value]
                  : {
                      [indicatorsConfig[0].id]:
                        indicatorsConfig[0].unit === Units.Percent
                          ? dim2Data[dim2Value]?.[indicatorsConfig[0].id]
                          : dim2Data[dim2Value],
                    }),
              },
            ],
            [],
          ),
        ];
      }, []);
    }
    return data.brainpower.data;
  }, [data]);

  // height * unit + (height-1) * margin - tile padding - tile header - table header
  const tableHeight = layout.h * 36 + (layout.h - 1) * 24 - 2 * 8 - 32 - 39;

  return (
    <div className={styles.root}>
      <Row justify="space-between" align="middle">
        <div className={styles.title}>{widget.name}</div>
        <DataExportMenu data={dataSource} widget={widget} />
      </Row>
      <AntTable
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        scroll={{ x: 'max-content', y: tableHeight }}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default Table;
