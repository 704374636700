import * as am4core from '@amcharts/amcharts4/core';
import { CategoryAxis, DateAxis, Legend, LineSeries, ValueAxis, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { compareByProp } from '../../../../../../util/array';
import { config as Dimensions } from '../../constants/dimensions';
import { config as Indicators } from '../../constants/indicators';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import Units from '../../constants/units';
import Axis from '../../constants/axis';
import { calculateTotals, percentGroupDataItemAdapter } from './common';
import { amountFormatter, numberFormatter } from '../../../../../../util/formatter';
import AmountFormats from '../../../../../../constants/amountFormats';
import NumberFormats from '../../../../../../constants/numberFormats';

const timezoneOffset = new Date().getTimezoneOffset();

// const dimensions = ['created_at', 'payment_method_name'];
// const indicators = ['success_rate'];
// const result = {
//   data: {
//     brainpower: {
//       data: [
//         {
//           created_at: '2022-09-12 18:00:00',
//           Checkout: { success_rate: 0, success_rate_numerator: 0, success_rate_denominator: 2 },
//           Iyzico: { success_rate: 0, success_rate_numerator: 0, success_rate_denominator: 3 },
//           Cybersource: { success_rate: 0, success_rate_numerator: 0, success_rate_denominator: 1 },
//         },
//         {
//           created_at: '2022-07-15 10:00:00',
//           Iyzico: { success_rate: 33.33, success_rate_numerator: 1, success_rate_denominator: 3 },
//           GarantiPay: { success_rate: 33.33, success_rate_numerator: 1, success_rate_denominator: 3 },
//         },
//       ],
//     },
//   },
// };

/**
 * Add Axis from unit
 * @param chart
 * @param unit
 * @param opposite - display axis on right side
 */
function addAxis(chart, unit, opposite) {
  const valueAxis = chart.yAxes.push(new ValueAxis());

  switch (unit) {
    case Units.Percent:
      valueAxis.strictMinMax = true;
      valueAxis.min = 0;
      valueAxis.max = 100;
      // valueAxis.title.text = 'Percent';
      break;
    case Units.Checkout:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Checkouts';
      break;
    case Units.Order:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Orders';
      break;
    case Units.Transaction:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Transactions';
      break;
    case Units.Amount:
      // valueAxis.title.text = 'EUR';
      break;
    case Units.Customer:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Customers';
      break;
    default:
      break;
  }

  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 2;
  valueAxis.renderer.opposite = opposite;
  valueAxis.renderer.grid.template.disabled = true;

  return valueAxis;
}

/**
 * Add Series from dimension value
 * @param chart
 * @param axis
 * @param name
 * @param label
 * @param dimension
 * @param indicator
 * @param currency
 * @param total
 */
function addGenericSeries(chart, axis, name, label, dimension, indicator, currency = 'EUR', total) {
  const series = chart.series.push(new LineSeries());

  series.dataFields.valueY = name;

  switch (dimension.axis) {
    case Axis.Date:
      series.dataFields.dateX = dimension.id;
      series.groupData = true;
      series.groupFields.valueY = 'sum';
      break;
    default:
      series.dataFields.categoryX = dimension.id;
      break;
  }

  series.strokeWidth = 2;

  series.yAxis = axis;
  series.yAxis.renderer.line.stroke = series.stroke;
  series.yAxis.renderer.labels.template.fill = series.stroke;
  series.name = label;

  switch (indicator.unit) {
    case Units.Amount:
      series.tooltipText = `{name}: [bold]${CURRENCIES[currency].symbol}{valueY.formatNumber('#,###.00')}[/]`;
      series.legendSettings.valueText = amountFormatter(total, currency, AmountFormats.US_CURRENCY);
      break;
    case Units.Percent:
      series.tooltipText = '{name}: [bold]{valueY}[/] %';
      series.adapter.add('groupDataItem', percentGroupDataItemAdapter);
      series.legendSettings.valueText = `${numberFormatter(total, NumberFormats.US, { maximumFractionDigits: 2 })} %`;
      break;
    default:
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.legendSettings.valueText = numberFormatter(total, NumberFormats.US);
      break;
  }

  series.showOnInit = true;
}

/**
 * Multiple value axes Chart
 * https://www.amcharts.com/demos/stacked-column-chart/
 * 2 dimensions (2nd dimension values are series)
 * 1 indicators (1rst indicator is axis unit)
 * @param divId
 * @param widget
 * @param result
 * @param args
 * @returns {XYChart}
 */
export default (divId, widget, result, args) => {
  const { dimensions, indicators, id } = widget;

  const widgetType = WidgetTypes.multiple_value_axes;

  // map dimensions
  const dimensionsConfig = dimensions.slice(0, widgetType.maxDimensions).map((d) => {
    if (Dimensions[d]) {
      return Dimensions[d];
    }
    throw new Error(`Dimension ${d} does not exist.`, id);
  });

  // map indicators
  const indicatorsConfig = indicators.slice(0, widgetType.maxIndicators).map((i) => {
    if (Indicators[i]) {
      return Indicators[i];
    }
    throw new Error(`Indicator ${indicators[0]} does not exist.`, id);
  });

  // Create chart instance
  const chart = am4core.create(divId, XYChart);

  // Create X Axes
  let xAxis;
  switch (dimensionsConfig[0].axis) {
    case Axis.Date:
      xAxis = chart.xAxes.push(new DateAxis());
      xAxis.groupData = true; // allow grouping data
      xAxis.groupCount = 72; // limit before grouping
      xAxis.renderer.grid.template.disabled = true;
      // Add cursor for date based axis (zoom)
      chart.cursor = new XYCursor();
      break;
    default:
      xAxis = chart.xAxes.push(new CategoryAxis());
      xAxis.dataFields.category = dimensionsConfig[0].id;
      break;
  }

  // One axis based on 1st indicator
  const axis = addAxis(chart, indicatorsConfig[0].unit);

  let chartData = [...result.data];
  let seriesList = [];
  if (dimensionsConfig.length === 2) {
    chartData = result.data.map((row) => {
      const chartRow = {};
      Object.keys(row).forEach((key) => {
        if (key === dimensions[0]) {
          chartRow[key] = row[key];
        } else {
          // find series in data (see result format above)
          if (!seriesList.includes(key)) {
            seriesList.push(key);
          }
          indicatorsConfig.forEach((ind) => {
            if (row[key]) {
              if (ind.unit === Units.Percent) {
                chartRow[`${key}-${ind.id}`] = row[key][ind.id];
                chartRow[`${key}-${ind.id}_numerator`] = row[key][`${ind.id}_numerator`];
                chartRow[`${key}-${ind.id}_denominator`] = row[key][`${ind.id}_denominator`];
              } else if (row[key][ind.id]) {
                chartRow[`${key}-${ind.id}`] = row[key][ind.id];
              } else {
                chartRow[`${key}-${ind.id}`] = row[key];
              }
            }
          });
        }
      });

      return chartRow;
    });
  } else if (dimensionsConfig.length === 1) {
    seriesList = indicatorsConfig.map((i) => i.id);
  }

  const totals = calculateTotals(dimensionsConfig, indicatorsConfig, result.data);

  seriesList.forEach((name) => {
    addGenericSeries(
      chart,
      axis,
      dimensionsConfig.length === 2 ? `${name}-${indicators[0]}` : name,
      dimensionsConfig.length === 1 ? Indicators[name].label : name, // TODO - get label from common
      dimensionsConfig[0],
      indicatorsConfig[0],
      args.currency,
      totals[name],
    );
  });

  // Add legend
  chart.legend = new Legend();

  // format number
  chart.numberFormatter.numberFormat = '#,###.##';

  // Increase contrast by taking every second color
  chart.colors.step = 2;
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm:ss';
  chart.dateFormatter.timezoneOffset = timezoneOffset;

  // Add sorted data
  chart.data = chartData.sort(compareByProp(dimensions[0]));

  return chart;
};
