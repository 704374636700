import * as am4core from '@amcharts/amcharts4/core';
import { ForceDirectedSeries, ForceDirectedTree } from '@amcharts/amcharts4/plugins/forceDirected';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import Units from '../../constants/units';
import { config as Indicators } from '../../constants/indicators';

/**
 * ForceDirectTree
 * @param divId
 * @param widget
 * @param data
 * @param args
 * @returns {ForceDirectedTree}
 */
export default (divId, widget, data, args) => {
  const { dimensions, indicators } = widget;

  const chart = am4core.create(divId, ForceDirectedTree);
  chart.padding(0, 0, 0, 0);
  const networkSeries = chart.series.push(new ForceDirectedSeries());

  chart.hiddenState.properties.opacity = 0;

  const indicator = Indicators[indicators[0]];

  // Inject data in chart
  chart.data = [
    {
      name: args.programManagerName,
      children: data.data.map((row) => {
        const { [dimensions[0]]: name, ...values } = row;
        return {
          name,
          children: Object.keys(values).map((key) => ({
            name: key,
            value: values[key],
          })),
        };
      }),
    },
  ];

  // Configure icons
  const icon = networkSeries.nodes.template.createChild(am4core.Image);
  icon.propertyFields.href = 'icon';
  icon.horizontalCenter = 'middle';
  icon.verticalCenter = 'middle';
  icon.width = 25;
  icon.strokeWidth = 0;

  networkSeries.dataFields.value = 'value';
  networkSeries.dataFields.name = 'name';
  networkSeries.dataFields.children = 'children';

  networkSeries.minRadius = 17;
  networkSeries.maxRadius = 55;
  networkSeries.fontSize = 10;
  networkSeries.manyBodyStrength = -30;

  switch (indicator.unit) {
    case Units.Percent:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        "[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#.##')} %[/] [#000]{additional}[/]";
      break;
    case Units.Transaction:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        "[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#')} transactions[/] [#000]{additional}[/]";
      break;
    case Units.Checkout:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        "[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#')} checkouts[/] [#000]{additional}[/]";
      break;
    case Units.Order:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        "[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#')} orders[/] [#000]{additional}[/]";
      break;
    case Units.Amount:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        `[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#,###.00')} ${CURRENCIES[args.currency].symbol}[/] [#000]{additional}[/]`;
      break;
    default:
      networkSeries.nodes.template.tooltipText =
        '[#fff font-size: 12px text-transform: capitalize word-break: keep-all]{name}\n' +
        `[/][#000 font-weight:600 font-size: 12px]{value.formatNumber('#')}[/] [#000]{additional}[/]`;
  }

  networkSeries.nodes.template.fillOpacity = 1;
  networkSeries.nodes.template.label.text = '[#242424 text-transform: capitalize]{name}[/]';
  networkSeries.nodes.template.label.truncate = true;
  // networkSeries.nodes.template.propertyFields.fill = "color";

  networkSeries.links.template.strokeWidth = 2;
  networkSeries.links.template.distance = 2.3;

  networkSeries.tooltip.background.cornerRadius = 0;
  networkSeries.tooltip.background.strokeOpacity = 0;

  // on hover > apply hover state to all link (child & parent)
  const hoverState = networkSeries.links.template.states.create('hover');
  hoverState.properties.strokeWidth = 6;
  hoverState.properties.strokeOpacity = 1;

  networkSeries.nodes.template.events.on('over', (event) => {
    event.target.dataItem.childLinks.each((link) => {
      // eslint-disable-next-line no-param-reassign
      link.isHover = true;
    });
    if (event.target.dataItem.parentLink) {
      // eslint-disable-next-line no-param-reassign
      event.target.dataItem.parentLink.isHover = true;
    }
  });

  networkSeries.nodes.template.events.on('out', (event) => {
    event.target.dataItem.childLinks.each((link) => {
      // eslint-disable-next-line no-param-reassign
      link.isHover = false;
    });
    if (event.target.dataItem.parentLink) {
      // eslint-disable-next-line no-param-reassign
      event.target.dataItem.parentLink.isHover = false;
    }
  });

  return chart;
};
