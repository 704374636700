import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Drawer, Input } from 'antd';
import ArrowLeftIcon from '@2fd/ant-design-icons/lib/ArrowLeft';
import TrashCanIcon from '@2fd/ant-design-icons/lib/TrashCan';
import WindowCloseIcon from '@2fd/ant-design-icons/lib/WindowClose';
import { Block, BlockContent } from '../../../../../Common/Sider';
import Item from '../../../../../Common/SiderList/Item';
import { config as WidgetTypes } from '../../../constants/widgetTypes';
import { useDashboard, useSelectedWidgetId } from '../../../dashboard.context';
import DimensionSortableList from './DimensionSortableList';
import IndicatorSortableList from './IndicatorSortableList';
import styles from './WidgetEditDrawer.module.scss';
import DimensionAddableList from './DimensionAddableList';
import IndicatorAddableList from './IndicatorAddableList';
import WidgetOptions from './Options/Options';
import WidgetContext from './widget.context';
import useDebounce from '../../../../../../../hooks/useDebounce';

const WidgetEditContent = () => {
  const [widgetId, setSelectedWidgetId] = useSelectedWidgetId();

  const { dashboard, editable, updateWidget, removeWidget } = useDashboard();

  const widget = widgetId && dashboard.widgets[widgetId];

  const widgetType = useMemo(() => {
    if (widget) {
      if (WidgetTypes[widget.type]) {
        return WidgetTypes[widget.type];
      }
      throw new Error(`Widget type ${widget.type} does not exist. (${widgetId})`);
    }
    return null;
  }, [widget]);

  // update name with debounce
  const [name, setName] = useState(widget?.name);
  const debouncedName = useDebounce(name, 300);
  useEffect(() => {
    if (debouncedName && debouncedName !== widget?.name) {
      updateWidget({ ...widget, name: debouncedName });
    }
  }, [updateWidget, widget, debouncedName]);

  const handleChangeName = (e) => setName(e.target.value);

  const handleRemoveWidget = () => {
    removeWidget(widgetId);
    setSelectedWidgetId();
  };

  if (!widgetId) return null;

  if (!widget) {
    return (
      <Block
        key="edit-chart"
        title="Edit widget"
        description="Select a widget in the grid to edit it"
        onBackClick={() => setSelectedWidgetId()}
      >
        <div>Something went wrong, please retry later.</div>
        {editable && (
          <Button shape="text" icon={<TrashCanIcon />} onClick={handleRemoveWidget} title="Delete widget">
            Delete widget
          </Button>
        )}
      </Block>
    );
  }

  return (
    <WidgetContext.Provider
      value={{
        widget,
        updateWidget,
        editable,
      }}
    >
      <Block key="edit-chart" title="Edit widget" description="Select a widget in the grid to edit it">
        <div className={styles.flex}>
          <Input
            value={name}
            onChange={handleChangeName}
            placeholder="Widget name"
            className={styles.inputName}
            bordered={false}
            size="middle"
            disabled={!editable}
          />
          {editable && (
            <Button
              className={styles.deleteButton}
              shape="text"
              icon={<TrashCanIcon />}
              onClick={handleRemoveWidget}
              title="Delete widget"
            />
          )}
        </div>
        <Item
          id={widgetType.id}
          label={widgetType.label}
          title={widgetType.label}
          color={widgetType.color}
          icon={widgetType.icon}
        />
        {!!widgetType.minDimensions && <DimensionSortableList />}
        {!!widgetType.minIndicators && <IndicatorSortableList />}
      </Block>
      {editable && [
        !!widgetType.minDimensions && <DimensionAddableList key="dimensions-addable" />,
        !!widgetType.minIndicators && <IndicatorAddableList key="indicators-addable" />,
      ]}
      {widgetType.options?.length > 0 && <WidgetOptions />}
      {widgetType.alert && (
        <BlockContent>
          <Alert style={{ fontSize: '12px' }} showIcon message={widgetType.alert} />
        </BlockContent>
      )}
      {widgetType.info && (
        <BlockContent>
          <div
            style={{ fontSize: '12px', textAlign: 'justify' }}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: widgetType.info }}
          />
        </BlockContent>
      )}
    </WidgetContext.Provider>
  );
};

const WidgetEditDrawer = ({ open, onClose, onBack }) => {
  const [widgetId] = useSelectedWidgetId();
  return (
    <Drawer
      width={470}
      open={open}
      getContainer="#dashboard"
      style={{ position: 'absolute', zIndex: 52 }}
      bodyStyle={{ padding: 0 }}
      onClose={onClose}
      destroyOnClose
      title="Edit Widget"
      closable
      closeIcon={<WindowCloseIcon />}
      extra={<ArrowLeftIcon onClick={onBack} />}
      mask={false}
      push={false}
      autoFocus={false}
    >
      <WidgetEditContent key={widgetId} />
    </Drawer>
  );
};
export default WidgetEditDrawer;
