import * as am4core from '@amcharts/amcharts4/core';
import {
  CategoryAxis,
  // Bullet,
  CircleBullet,
  ColumnSeries,
  DateAxis,
  Legend,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from '@amcharts/amcharts4/charts';
import CURRENCIES from 'norbr-shared-lib/constants/currencies';
import { compareByProp } from '../../../../../../util/array';
import { config as Dimensions } from '../../constants/dimensions';
import { config as Indicators } from '../../constants/indicators';
import SeriesTypes from '../../constants/seriesTypes';
import { config as WidgetTypes } from '../../constants/widgetTypes';
import Units from '../../constants/units';
import Axis from '../../constants/axis';
import { calculateTotals, percentGroupDataItemAdapter } from './common';
import { amountFormatter, numberFormatter } from '../../../../../../util/formatter';
import NumberFormats from '../../../../../../constants/numberFormats';
import AmountFormats from '../../../../../../constants/amountFormats';

const timezoneOffset = new Date().getTimezoneOffset();

/**
 * Add Axis from unit
 * @param chart
 * @param unit
 * @param opposite - display axis on right side
 */
function addAxis(chart, unit, opposite) {
  const valueAxis = chart.yAxes.push(new ValueAxis());

  switch (unit) {
    case Units.Percent:
      valueAxis.strictMinMax = true;
      valueAxis.min = 0;
      valueAxis.max = 100;
      // valueAxis.title.text = 'Percent';
      break;
    case Units.Checkout:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Checkouts';
      break;
    case Units.Order:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Orders';
      break;
    case Units.Transaction:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Transactions';
      break;
    case Units.Amount:
      // valueAxis.title.text = 'EUR';
      break;
    case Units.Customer:
      valueAxis.min = 0;
      // valueAxis.title.text = 'Customers';
      break;
    default:
      break;
  }

  valueAxis.renderer.line.strokeOpacity = 1;
  valueAxis.renderer.line.strokeWidth = 2;
  valueAxis.renderer.opposite = opposite;
  valueAxis.renderer.grid.template.disabled = true;

  return valueAxis;
}

/**
 * Add Series from indicator
 * @param chart
 * @param axisList
 * @param indicator
 * @param dimension
 * @param currency
 * @param total
 */
function addIndicatorSeries(chart, axisList, indicator, dimension, currency = 'EUR', total) {
  let series;
  let bullet;
  switch (indicator.seriesType) {
    case SeriesTypes.column:
      series = chart.series.push(new ColumnSeries());
      break;
    case SeriesTypes.line:
    default:
      series = chart.series.push(new LineSeries());
      bullet = series.bullets.push(new CircleBullet());
      bullet.stroke = new am4core.InterfaceColorSet().getFor('background');
      bullet.strokeWidth = 2;
      // hide bullet if too close
      series.minBulletDistance = 40;
      break;
  }

  series.dataFields.valueY = indicator.id;

  switch (dimension.axis) {
    case Axis.Date:
      series.dataFields.dateX = dimension.id;
      break;
    default:
      series.dataFields.categoryX = dimension.id;
      break;
  }

  series.groupFields.valueY = 'sum';
  series.strokeWidth = 2;
  series.yAxis = axisList[indicator.unit];
  series.yAxis.renderer.line.stroke = series.stroke;
  series.yAxis.renderer.labels.template.fill = series.stroke;
  series.name = indicator.label;

  switch (indicator.unit) {
    case Units.Amount:
      series.tooltipText = `{name}: [bold]${CURRENCIES[currency].symbol}{valueY.formatNumber('#,###.00')}[/]`;
      series.legendSettings.valueText = amountFormatter(total, currency, AmountFormats.US_CURRENCY);
      break;
    case Units.Percent:
      series.tooltipText = '{name}: [bold]{valueY}[/] %';
      series.adapter.add('groupDataItem', percentGroupDataItemAdapter);
      series.legendSettings.valueText = `${numberFormatter(total, NumberFormats.US, { maximumFractionDigits: 2 })} %`;
      break;
    default:
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.legendSettings.valueText = numberFormatter(total, NumberFormats.US);
      break;
  }

  series.showOnInit = true;
}

/**
 * Column/Line mix chart
 * 1 or 2 dimensions
 * 1+ indicators
 * 2nd dimension (optional) values are series.
 * In this case, 1rst indicator is axis unit, others are displayed in tooltips.
 * @param divId
 * @param widget
 * @param data
 * @returns {XYChart}
 */
export default (divId, widget, data, args) => {
  const { dimensions, indicators, id } = widget;

  const widgetType = WidgetTypes.column_line_mix;

  const dimensionsConfig = dimensions.slice(0, widgetType.maxDimensions).map((d) => {
    if (Dimensions[d]) {
      return Dimensions[d];
    }
    throw new Error(`Dimension ${d} does not exist.`, id);
  });

  const indicatorsConfig = indicators.slice(0, widgetType.maxIndicators).map((i) => {
    if (Indicators[i]) {
      return Indicators[i];
    }
    throw new Error(`Indicator ${i} does not exist.`, id);
  });

  // Create chart instance
  const chart = am4core.create(divId, XYChart);

  // Create X Axes
  let xAxis;
  switch (dimensionsConfig[0].axis) {
    case Axis.Date:
      xAxis = chart.xAxes.push(new DateAxis());
      xAxis.groupData = true; // allow grouping data
      xAxis.groupCount = 72; // limit before grouping
      xAxis.renderer.grid.template.disabled = true;
      break;
    default:
      xAxis = chart.xAxes.push(new CategoryAxis());
      xAxis.dataFields.category = dimensionsConfig[0].id;
      break;
  }

  // As many axis as different indicator units
  // TODO : enable more unit / yAxes config ?
  // Add axis from indicators unit
  // ( share axis for indicator with same unit )
  const axisList = {};
  const units = indicatorsConfig
    .map((i) => i.unit) // get units
    .filter((u, pos, l) => l.indexOf(u) === pos); // dedupe
  units.forEach((unit, idx) => {
    // @ts-ignore
    axisList[unit] = addAxis(chart, unit, idx > 0);
  });

  const totals = calculateTotals(dimensionsConfig, indicatorsConfig, data.data);

  // Add series from indicators
  indicatorsConfig.forEach((indicator) => {
    addIndicatorSeries(chart, axisList, indicator, dimensionsConfig[0], args.currency, totals[indicator.id]);
  });

  // Add legend
  chart.legend = new Legend();

  // Add cursor
  chart.cursor = new XYCursor();
  // chart.cursor.behavior = 'none'; // disable zooming

  // format number
  chart.numberFormatter.numberFormat = '#,###.##';

  // Increase contrast by taking every second color
  chart.colors.step = 2;
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd HH:mm:ss';
  chart.dateFormatter.timezoneOffset = timezoneOffset;

  // Add sorted data
  chart.data = [...data.data].sort(compareByProp(dimensions[0]));

  return chart;
};
