import React, { useCallback, useMemo } from 'react';
import { Switch, Slider, Select, Space } from 'antd';
import { config as WidgetOptionsConfig, WidgetOptionTypes } from '../../../../constants/widgetOptions';

const OptionBuilder = ({ name, value, onChange, disabled }) => {
  const option = useMemo(() => {
    if (name) {
      if (WidgetOptionsConfig[name]) {
        return WidgetOptionsConfig[name];
      }
      throw new Error(`Widget option ${name} does not exist.`);
    }
    return null;
  }, [name]);

  const handleChange = useCallback(
    (_value) => {
      onChange(name, _value);
    },
    [name, onChange],
  );

  switch (option.type) {
    case WidgetOptionTypes.Boolean:
      return (
        <Space title={option.description}>
          <Switch
            size="small"
            defaultChecked={value ?? option.defaultValue}
            onChange={handleChange}
            disabled={disabled}
          />
          <span style={{ fontSize: '11px' }}>{option.label}</span>
        </Space>
      );

    case WidgetOptionTypes.Slider:
      return (
        <Space title={option.description}>
          <Slider
            min={2}
            max={20}
            onChange={handleChange}
            value={typeof value === 'number' ? value : 0}
            color="primary"
            style={{ width: 160 }}
            disabled={disabled}
          />
          <span style={{ fontSize: '11px' }}>{option.label(value)}</span>
        </Space>
      );

    case WidgetOptionTypes.Select:
      return (
        <Space direction="vertical">
          <Space title={option.description}>
            <div style={{ fontSize: '11px', width: 120, textAlign: 'right' }}>{option.label}</div>
            <Select
              value={value ?? option.defaultValue}
              onChange={handleChange}
              options={option.options}
              style={{ width: 160 }}
              disabled={disabled}
            />
          </Space>
        </Space>
      );

    default:
      break;
  }

  return null;
};

export default OptionBuilder;
