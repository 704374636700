import {
  CrownOutlined,
  DotChartOutlined,
  FieldBinaryOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  FunnelPlotOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
} from '@ant-design/icons';
import LinkVariantOffIcon from '@2fd/ant-design-icons/lib/LinkVariantOff';
import { Bar, Donut, Radar, RadarArea, StackBar, Timeline } from '../icons';
import Axis from './axis';
import Dimensions from './dimensions';
import Indicators from './indicators';
import Units from './units';
import WidgetOptions from './widgetOptions';
import { compareByProp } from '../../../../../util/array';
import AmountFormats from '../../../../../constants/amountFormats';

enum WidgetTypes {
  bubble = 'bubble',
  columnLineMix = 'column_line_mix',
  countriesMorphingToPie = 'countries_morphing_to_pie',
  financialAnalysis = 'financial_analysis',
  forceDirectTree = 'force_direct_tree',
  horizontalFunnel = 'horizontal_funnel',
  kpi = 'kpi',
  lifetimeValue = 'lifetime_value',
  matcherUnreconciledNotifications = 'matcher_unreconciled_notifications',
  matcherUnreconciledOperations = 'matcher_unreconciled_operations',
  multipleValueAxes = 'multiple_value_axes',
  notepad = 'notepad',
  pie = 'pie',
  pieInColumn = 'pie_in_column',
  pieOfAPie = 'pie_of_a_pie',
  radar = 'radar',
  radarTimeline = 'radar_timeline',
  rfmTreemap = 'rfm_treemap',
  stackedColumn = 'stacked_column',
  table = 'table',
  traceableSankeyDiagram = 'traceable_sankey_diagram',
  treemap = 'treemap',
}

export const enumList = Object.values(WidgetTypes);

export const config = {
  [WidgetTypes.kpi]: {
    id: WidgetTypes.kpi,
    minW: 4,
    minH: 2,
    isResizable: false,
    exportable: false,
    label: 'KPI',
    icon: FieldBinaryOutlined,
    endpoint: 'kpi',
    options: [
      { name: WidgetOptions.kpi, defaultValue: 'authorize' },
      { name: WidgetOptions.amountFormat, defaultValue: AmountFormats.FR_CURRENCY },
    ],
    info:
      '- Authorized Turnover: The total sum of authorized amounts. The reference date is the transaction creation date. The "Orders" number represents the distinct count of authorized orders.<br><br>' +
      '- Success Rate: The ratio of the number of authorized transactions to the total number of transactions, excluding those where the transaction type is "Authentication." The reference date is the transaction creation date.<br><br>' +
      '- Capture Turnover: The total sum of captured amounts. The reference date is the transaction capture date. The "Orders" number represents the distinct count of captured orders.<br><br>' +
      '- Average Basket: The average value per authorized order, calculated as the authorized turnover divided by the number of authorized orders. The reference date is the transaction creation date.<br><br>' +
      '- Refunds Amount: The total sum of refunded amounts. The reference date is the transaction refund date. The "Orders" number represents the distinct count of refunded orders.<br><br>' +
      '- Chargebacks Amount: The total sum of chargeback amounts. The reference date is the transaction chargeback date. The "Orders" number represents the distinct count of chargeback orders.',
  },
  [WidgetTypes.columnLineMix]: {
    id: WidgetTypes.columnLineMix,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Column/Line mix',
    icon: Bar,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 1,
    minIndicators: 1,
    maxIndicators: 8,
  },
  [WidgetTypes.stackedColumn]: {
    id: WidgetTypes.stackedColumn,
    minW: 4,
    minH: 6,
    isResizable: true,
    exportable: true,
    label: 'Stacked column',
    icon: StackBar,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenIndicatorTypes: [Units.Percent],
  },
  [WidgetTypes.pie]: {
    id: WidgetTypes.pie,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Donut chart',
    icon: Donut,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 1,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenDimensionTypes: [Axis.Date],
    forbiddenIndicatorTypes: [Units.Percent],
    options: [{ name: WidgetOptions.sliceItems, defaultValue: 8 }],
  },
  [WidgetTypes.bubble]: {
    id: WidgetTypes.bubble,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Bubble chart',
    icon: DotChartOutlined,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 1,
    minIndicators: 3,
    maxIndicators: 8,
    forbiddenDimensionTypes: [Axis.Date],
    options: [{ name: WidgetOptions.logarithmicAxis, defaultValue: false }],
    info: '1st indicator is used for X axis.<br>2nd indicator is used for Y axis.<br>3rd indicator is used for bubbles size.',
  },
  [WidgetTypes.radar]: {
    id: WidgetTypes.radar,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: false,
    label: 'Radar chart',
    icon: RadarArea,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 1,
    minIndicators: 1,
    maxIndicators: 8,
    forbiddenDimensionTypes: [Axis.Date],
  },
  [WidgetTypes.forceDirectTree]: {
    id: WidgetTypes.forceDirectTree,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Force direct tree',
    icon: Timeline,
    endpoint: 'serial',
    minDimensions: 2,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenDimensionTypes: [Axis.Date],
    forbiddenIndicatorTypes: [Units.Percent],
  },
  [WidgetTypes.radarTimeline]: {
    id: WidgetTypes.radarTimeline,
    editable: false,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: false,
    label: 'Radar timeline',
    icon: Radar,
    endpoint: 'radar_timeline',
    minDimensions: 3,
    maxDimensions: 3,
    minIndicators: 1,
    maxIndicators: 1,
  },
  [WidgetTypes.pieInColumn]: {
    id: WidgetTypes.pieInColumn,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Pie in column',
    icon: StackBar,
    endpoint: 'serial',
    minDimensions: 2,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenDimensionTypes: [Axis.Date],
    forbiddenIndicatorTypes: [Units.Percent],
    options: [{ name: WidgetOptions.sliceItems, defaultValue: 8 }],
  },
  [WidgetTypes.traceableSankeyDiagram]: {
    id: WidgetTypes.traceableSankeyDiagram,
    editable: false,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Sankey diagram',
    icon: Timeline,
    endpoint: 'traceable_sankey_diagram',
  },
  [WidgetTypes.rfmTreemap]: {
    id: WidgetTypes.rfmTreemap,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: false,
    label: 'Customer RFM',
    icon: CrownOutlined,
    endpoint: 'rfm',
    options: [
      { name: WidgetOptions.customerIdentifier, defaultValue: 'customer_email' },
      { name: WidgetOptions.complexAnalysisPeriod, defaultValue: 'month' },
    ],
    params: (widget: any) => ({
      dimensions: [widget.options?.[WidgetOptions.customerIdentifier] ?? 'customer_email'],
      complex_analysis_period: widget.options?.[WidgetOptions.complexAnalysisPeriod] ?? 'month',
    }),
    info:
      'The “RFM” analysis stands for Recency, Frequency and Monetary value.<br><br>' +
      'The RFM analysis is a segmentation of the customer database, based on existing customer behavior to predict how a new customer is likely to act in the future.',
    alert:
      'Please select All as period for your dashboard with transaction date as reference date, or the results of the RFM calculation may be incomplete.',
  },
  [WidgetTypes.lifetimeValue]: {
    id: WidgetTypes.lifetimeValue,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Customer LTV',
    icon: FundProjectionScreenOutlined,
    endpoint: 'ltv',
    options: [{ name: WidgetOptions.customerIdentifier, defaultValue: 'customer_email' }],
    params: (widget: any) => ({
      dimensions: [widget.options?.[WidgetOptions.customerIdentifier] ?? 'customer_email'],
    }),
    info:
      'Customer lifetime value (Customer LTV or CLTV) is a metric that indicates the average revenue by customers after 3, 6, 12, and 24 months following the acquisition month (month of the first authorized transaction).<br><br>' +
      'This marketing analysis aims to calculate the return on acquisition investments and the revenue a business can reasonably expect from a single customer account throughout the business relationship.',
    alert: 'Please select All as period for your dashboard, or the results of the LTV calculation may be incomplete.',
  },
  [WidgetTypes.matcherUnreconciledOperations]: {
    id: WidgetTypes.matcherUnreconciledOperations,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Unmatched operations',
    icon: LinkVariantOffIcon,
    endpoint: 'matcher_unreconciled_operations',
    info: 'Unmatched operations are captures or refunds that have not been reconciled with a notification from the PSP. The reference date refers to the date of the POS capture or refund.',
  },
  [WidgetTypes.matcherUnreconciledNotifications]: {
    id: WidgetTypes.matcherUnreconciledNotifications,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Unmatched notifications',
    icon: LinkVariantOffIcon,
    endpoint: 'matcher_unreconciled_notifications',
    info: 'Unmatched notifications are notifications received from the PSP that have not been reconciled with a POS payment. The reference date refers to the date the notifications were received.',
  },
  [WidgetTypes.table]: {
    id: WidgetTypes.table,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Table',
    icon: TableOutlined,
    endpoint: 'serial',
    params: () => ({
      include_null_dates: true,
    }),
    minDimensions: 1,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 8,
    options: [{ name: WidgetOptions.amountFormat, defaultValue: AmountFormats.FR_CURRENCY }],
  },
  [WidgetTypes.multipleValueAxes]: {
    id: WidgetTypes.multipleValueAxes,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Multiple value axes',
    icon: LineChartOutlined,
    endpoint: 'serial',
    minDimensions: 2,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
  },
  [WidgetTypes.financialAnalysis]: {
    id: WidgetTypes.financialAnalysis,
    editable: false,
    minW: 4,
    minH: 7,
    isResizable: true,
    exportable: true,
    label: 'Financial analysis',
    icon: FundProjectionScreenOutlined,
    endpoint: 'financial_analysis',
  },
  [WidgetTypes.treemap]: {
    id: WidgetTypes.treemap,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Treemap',
    icon: LayoutOutlined,
    endpoint: 'serial',
    minDimensions: 1,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenDimensionTypes: [Axis.Date],
    forbiddenIndicatorTypes: [Units.Percent],
    options: [{ name: WidgetOptions.sliceItems, defaultValue: 8 }],
  },
  [WidgetTypes.pieOfAPie]: {
    id: WidgetTypes.pieOfAPie,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Pie of a Pie',
    icon: Donut,
    endpoint: 'serial',
    minDimensions: 2,
    maxDimensions: 2,
    minIndicators: 1,
    maxIndicators: 1,
    forbiddenDimensionTypes: [Axis.Date],
    forbiddenIndicatorTypes: [Units.Percent],
    options: [{ name: WidgetOptions.sliceItems, defaultValue: 8 }],
  },
  [WidgetTypes.horizontalFunnel]: {
    id: WidgetTypes.horizontalFunnel,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: true,
    label: 'Funnel',
    icon: FunnelPlotOutlined,
    endpoint: 'serial',
    minIndicators: 1,
    maxIndicators: 10,
    forbiddenDimensionTypes: [Axis.Date],
    options: [{ name: WidgetOptions.orientation, defaultValue: 'horizontal' }],
  },
  // [WidgetTypes.countriesMorphingToPie]: {
  //   id: WidgetTypes.countriesMorphingToPie,
  //   minW: 4,
  //   minH: 4,
  //   isResizable: true,
  //   label: 'Countries morphing to pie',
  //   icon: GlobalOutlined,
  //   endpoint: 'serial',
  //   minDimensions: 1,
  //   maxDimensions: 1,
  //   minIndicators: 1,
  //   maxIndicators: 1,
  //   forbiddenDimensionTypes: [Axis.Date],
  //   params: (widget: any) => ({
  //     dimensions: [Dimensions.cardCountry, ...widget.dimensions],
  //   }),
  //   options: [{ name: WidgetOptions.sliceItems, defaultValue: 8 }],
  // },
  [WidgetTypes.notepad]: {
    id: WidgetTypes.notepad,
    minW: 4,
    minH: 4,
    isResizable: true,
    exportable: false,
    label: 'Notepad',
    icon: FormOutlined,
  },
};

export const list = Object.values(config).sort(compareByProp('label', 'asc', 'string'));

export const defaultWidgets = {
  [WidgetTypes.kpi]: {
    name: 'Key Performance Indicators',
    h: 2,
    w: 4,
    options: { [WidgetOptions.kpi]: 'authorize', [WidgetOptions.amountFormat]: AmountFormats.FR_CURRENCY },
  },
  [WidgetTypes.columnLineMix]: {
    name: 'New Column/Line mix chart',
    dimensions: [Dimensions.orderCreationDate],
    indicators: [Indicators.authorizedOrders, Indicators.authorizedTurnover],
    h: 6,
    w: 6,
  },
  [WidgetTypes.stackedColumn]: {
    name: 'New Stacked column chart',
    dimensions: [Dimensions.payinPartner, Dimensions.paymentMethodName],
    indicators: [Indicators.authorizedOrders],
    h: 6,
    w: 6,
  },
  [WidgetTypes.pie]: {
    name: 'New Donut chart',
    dimensions: [Dimensions.payinPartner],
    indicators: [Indicators.authorizedOrders],
    h: 6,
    w: 6,
  },
  [WidgetTypes.bubble]: {
    name: 'New Bubble chart',
    dimensions: [Dimensions.payinPartner],
    indicators: [Indicators.successRate, Indicators.allOrders, Indicators.averageBasket],
    h: 6,
    w: 6,
  },
  [WidgetTypes.radar]: {
    name: 'New Radar chart',
    dimensions: [Dimensions.payinPartner],
    indicators: [
      Indicators.successRate,
      Indicators.transactionAuthenticationRate,
      Indicators.transactionBlockRate,
      Indicators.transactionRepeatRate,
      Indicators.transactionSavedRate,
    ],
    h: 6,
    w: 6,
  },
  [WidgetTypes.forceDirectTree]: {
    name: 'New Force direct tree',
    dimensions: [Dimensions.payinPartner, Dimensions.paymentMethodName],
    indicators: [Indicators.authorizedOrders],
    h: 6,
    w: 6,
  },
  [WidgetTypes.pieInColumn]: {
    name: 'New Pie in column',
    dimensions: [Dimensions.customerCountry, Dimensions.paymentMethodName],
    indicators: [Indicators.authorizedOrders, Indicators.authorizedTurnover],
    h: 6,
    w: 6,
  },
  [WidgetTypes.radarTimeline]: {
    name: 'New Radar timeline',
    dimensions: [Dimensions.orderCreationDate, Dimensions.payinPartner, Dimensions.paymentMethodName],
    indicators: [Indicators.authorizedOrders, Indicators.authorizedTurnover],
    h: 6,
    w: 6,
  },
  [WidgetTypes.traceableSankeyDiagram]: {
    name: 'New Sankey diagram',
    h: 6,
    w: 12,
  },
  [WidgetTypes.rfmTreemap]: {
    name: 'Customer RFM',
    h: 6,
    w: 12,
  },
  [WidgetTypes.lifetimeValue]: {
    name: 'Customer LTV',
    h: 6,
    w: 12,
  },
  [WidgetTypes.matcherUnreconciledOperations]: {
    name: 'Unmatched operations',
    h: 6,
    w: 6,
  },
  [WidgetTypes.matcherUnreconciledNotifications]: {
    name: 'Unmatched notifications',
    h: 6,
    w: 6,
  },
  [WidgetTypes.table]: {
    name: 'New Table',
    dimensions: [Dimensions.payinPartner],
    indicators: [Indicators.allTransactions],
    h: 6,
    w: 6,
    options: { [WidgetOptions.amountFormat]: AmountFormats.FR_CURRENCY },
  },
  [WidgetTypes.multipleValueAxes]: {
    name: 'New Multiple value axes chart',
    dimensions: [Dimensions.orderCreationDate, Dimensions.payinPartner],
    indicators: [Indicators.successRate],
    h: 6,
    w: 6,
  },
  [WidgetTypes.financialAnalysis]: {
    name: 'Financial analysis',
    h: 7,
    w: 6,
  },
  [WidgetTypes.treemap]: {
    name: 'New Treemap chart',
    h: 6,
    w: 6,
    dimensions: [Dimensions.payinPartner],
    indicators: [Indicators.authorizedOrders],
  },
  [WidgetTypes.pieOfAPie]: {
    name: 'New Pie of a pie chart',
    h: 6,
    w: 6,
    dimensions: [Dimensions.payinPartner, Dimensions.paymentMethodName],
    indicators: [Indicators.authorizedOrders],
  },
  [WidgetTypes.horizontalFunnel]: {
    name: 'New Horizontal funnel chart',
    h: 6,
    w: 6,
    dimensions: [Dimensions.noDimension],
    indicators: [
      Indicators.allTransactions,
      Indicators.authorizedTransactions,
      Indicators.capturedTransactions,
      Indicators.refundedTransactions,
      Indicators.chargebackTransactions,
    ],
    options: { [WidgetOptions.orientation]: 'horizontal' },
  },
  [WidgetTypes.countriesMorphingToPie]: {
    name: 'Countries morphing to pie',
    h: 6,
    w: 6,
    dimensions: [Dimensions.payinPartner],
    indicators: [Indicators.authorizedOrders],
  },
  [WidgetTypes.notepad]: {
    name: 'Notepad',
    h: 6,
    w: 6,
  },
};

export default WidgetTypes;
