import React from 'react';
import ErrorBoundary from '../../../Common/ErrorBoundary/ErrorBoundary';
import WidgetTypes from '../constants/widgetTypes';
import styles from './widget.module.scss';
import Chart from './Models/Chart';
import Kpi from './Models/Kpi';
import Notepad from './Models/Notepad';
import Table from './Models/Table';
import FinancialAnalysis from './Models/FinancialAnalysis';
import MatcherUnreconciledOperations from './Models/MatcherUnreconciledOperations';
import MatcherUnreconciledNotifications from './Models/MatcherUnreconciledNotifications';

const WidgetSwitch = (props) => {
  const { widget, layout } = props;

  let Component;
  switch (widget.type) {
    case WidgetTypes.kpi:
      Component = Kpi;
      break;
    case WidgetTypes.notepad:
      Component = Notepad;
      break;
    case WidgetTypes.table:
      Component = Table;
      break;
    case WidgetTypes.financialAnalysis:
      Component = FinancialAnalysis;
      break;
    case WidgetTypes.matcherUnreconciledNotifications:
      Component = MatcherUnreconciledNotifications;
      break;
    case WidgetTypes.matcherUnreconciledOperations:
      Component = MatcherUnreconciledOperations;
      break;
    // generics
    case WidgetTypes.columnLineMix:
    case WidgetTypes.stackedColumn:
    case WidgetTypes.pie:
    case WidgetTypes.bubble:
    case WidgetTypes.radar:
    case WidgetTypes.forceDirectTree:
    case WidgetTypes.pieInColumn:
    case WidgetTypes.multipleValueAxes:
    case WidgetTypes.treemap:
    case WidgetTypes.pieOfAPie:
    case WidgetTypes.horizontalFunnel:
    case WidgetTypes.countriesMorphingToPie:
      Component = Chart;
      break;
    // specifics
    case WidgetTypes.radarTimeline:
    case WidgetTypes.traceableSankeyDiagram:
    case WidgetTypes.rfmTreemap:
    case WidgetTypes.lifetimeValue:
    default:
      Component = Chart;
      break;
  }

  return (
    <div className={styles.root}>
      <Component widget={widget} layout={layout} />
    </div>
  );
};

const Widget = (props) => (
  <ErrorBoundary>
    <WidgetSwitch {...props} />
  </ErrorBoundary>
);

export default Widget;
